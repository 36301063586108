<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item" [ngClass]="{ 'selection' : ((state.get('FocusedLibrary')?.value === item.value ) || (( state.get('FocusedLibrary')?.type === 'group' ) && state.get('FocusedLibrary')?.value === item.text )), 'bottom-margin': item.value !== 'Basics', 'library': !item.children, 'group': item.children }" (click)="handleFocus(item)">
    <i *ngIf="item.children" (click)="onCollapseExpand();handleFocus(item);$event.stopPropagation();" aria-hidden="true" class="fa treeview-arrow"
      [class.fa-caret-right]="item.collapsed"
      [class.fa-caret-down]="!item.collapsed">
        <img src="./assets/images/decoratives/expand-arrow.svg" width="18">
    </i>
    <div class="form-check form-input-wrapper" >
        <span>
            <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
                [disabled]="item.disabled || (((isPremiumLibrary(item.value)) || (isPremiumGroup(item.text))) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)))" [indeterminate]="item.indeterminate" />
            <span class="form-custom-checkbox">
            <img class="form-custom-checkbox-tick" src="./assets/images/decoratives/tick.svg" width="18">
            </span>
            <label [ngClass]="{ 'disabled' : item.disabled || (((isPremiumLibrary(item.value)) || (isPremiumGroup(item.text))) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)))}" class="form-check-label">
            <div class="library-name">{{item.text}}</div>
            <!-- <div *ngIf="(isPremiumGroup(item.value) && item.children) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async))"> -->
            <div *ngIf="(isPremiumGroup(item.text) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)))">
                <div class="premium">
                    <svg class="nu-icon" width="11px" hight="11px">
                        <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-premium-crown"></use>
                    </svg>
                </div>
            </div>
            </label>
        </span>
        <div *ngIf="item.count && item.count > 0" class="count-indicator">{{item.count}}</div>
    </div>
  </div>
</ng-template>

<div class="lib-browser-container modal-window-container fx-cover fx-center-all" #window>
    <div #windowInner class="modal-window-inner">
        <div class="modal-window-heading" tooltip-tour-step="fab-1.4.2">
            <h1 class="all-shapes-heading" translate="">LABELS.BROWSE_SHAPES</h1>
            <button class="nu-btn-small nu-btn-icon lib-browser-close-btn" (click)="closeWindow()">
                <svg class="nu-icon nu-icon-xsmall nu-ic-close">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                </svg>
            </button>
        </div>
        <div class="lib-browser-filter">
            <text-input
                [showClearButton]="true"
                icon="search-thin"
                [emitForInputChange]="true"
                placeholder="{{'PLACEHOLDERS.SHAPE_ADD_SEARCH' | translate}}"
                [debounce]="500"
                (updateText)="onFilterChange( $event )">
            </text-input>
        </div>
        <div *ngIf="(( isPremium | async ) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)))" class="premium-indicator fx-margin-top-10 d-flex">
            <svg class="nu-icon nu-info">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-info"></use>
            </svg>
            <span translate>LABELS.THIS_IS_A_PREMIUM_LIBRARY </span>
            <span (click)="openUpgradeDialog()" class="update-btn" translate>LABELS.UPGRADE_TO_GAIN_ACCESS</span>
        </div>
        <div class="library-panel-body">
            <div class="lib-browser-treeview" [ngClass]="{ 'hasPremium' : (( isPremium | async ) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)))}" >
                <perfect-scrollbar>
                    <ngx-treeview #treeView
                        [config]="config"
                        [items]="items | async"
                        [itemTemplate]="itemTemplate"
                        (selectedChange)="onSelectionChange($event)">
                    </ngx-treeview>
                </perfect-scrollbar>
            </div>
            <div class="library-browser-deepview" [ngClass]="{ 'hasPremium' : (( isPremium | async ) && ((isFreeOrDemoUser|async) || (isLitePlanUser|async)))}">
                <perfect-scrollbar>
                    <temp-add-libs-deepview></temp-add-libs-deepview>
                </perfect-scrollbar>
            </div>
        </div>
        <div class="select-btn-container">
            <button (click)="saveAndCloseWindow()" class="select-btn" 
            id="fab-library-btn" translate>BUTTONS.USE_SELECTED_SHAPES</button>
        </div>
    </div>
</div>